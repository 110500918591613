/**
 * Generate facet namespace
 * @type {}
 */
globals.facet = {};

globals.facet.init = function () {
    $('.js_facet-filter-source').on(globals.events.click, 'input', function () {
        globals.facet.resetFilter();
        globals.facet.filterResults();
    });
};


globals.facet.showNoResults = function () {
    $('.no-filter-available').removeClass('hidden');
};


globals.facet.filterResults = function () {
    var boxes = $('.js_facet-filter-source input[type="checkbox"]');

    // Loop through all checkboxes
    if (boxes.length !== $('.js_facet-filter-source input[type="checkbox"]:not(:checked)').length) {
        // Hide all unwanted data
        $('.js_facet-filter-source input[type="checkbox"]:not(:checked)').each(function () {
            $('.js_facet-filter-target .fga-teaser > li[data-filter="' + $(this).val() + '"] ').each(function () {
                $(this).addClass('seems-hidden');
            });
        });

        // Check if parent has visible childeren, else hide the parent too
        $('.js_facet-filter-target > div').each(function () {
            if ($(this).find('> ul > li').length === $(this).find('> ul > li.seems-hidden').length) {
                $(this).addClass('hidden');
            }
        });

        // No results
        if ($('.js_facet-filter-target > div').length === $('.js_facet-filter-target > div.hidden').length) {
            globals.facet.showNoResults();
        }
    }

};
globals.facet.resetFilter = function () {
    $('.js_facet-filter-target li.seems-hidden').removeClass('seems-hidden');
    $('.js_facet-filter-target > div').removeClass('hidden');
    $('.no-filter-available').addClass('hidden');
};
