/**
 * Generate location namespace
 * @type {}
 */
globals.location = {};
globals.location.currentMap = '';

function checkState() {
  var success = false;
  if (!_.isUndefined('globals')) {
    if (_.isFunction(globals.location.initMap)) {
      return true;
    }
  }
  if (!success) {
    setTimeout(checkState, 50);
  }
}
setTimeout(checkState, 50);

/**
 * Init function run by Google Maps V3 callback function
 */
globals.location.initMap = function () {
  var prevInfowindow = false;

  /**
   * Init map with custom styles & settings
   * @type {google.maps.Map}
   */
  globals.location.currentMap = new google.maps.Map(document.getElementById('map'), {
    zoom: 8, scrollwheel: false, navigationControl: false, //Center on first pin to init map
    center: fireMap[0], mapTypeControl: false, // Extra options
    zoomControl: true, zoomControlOptions: {
      position: google.maps.ControlPosition.LEFT_CENTER
    }, scaleControl: true, streetViewControl: true, streetViewControlOptions: {
      position: google.maps.ControlPosition.LEFT_CENTER
    }
  });

  /**
   * Global var to push all dynamic added markers
   * Needed to recalculate marker bounds later & center map
   * @type {Array}
   */
  globals.location.markers = [];

  var icons = {
    'school': {
      url: '/' + Drupal.settings.pathToTheme + '/img/pin-school.png',
      size: new google.maps.Size(55, 55),
      origin: new google.maps.Point(0, 0)
    },
    'zone': {
      url: '/' + Drupal.settings.pathToTheme + '/img/pin-zone.png',
      size: new google.maps.Size(55, 55),
      origin: new google.maps.Point(0, 0)
    }
  };

  /** Get all pointers & set as markers to map */
  _.each(fireMap, function (value) {
    // Create 1 or multiple pointers
    var marker = new google.maps.Marker({
      position: value,
      icon: icons[value.pointerType],
      animation: google.maps.Animation.DROP,
      map: globals.location.currentMap
    });
    /** If extra content is added, display as popup  */
    if (!_.isUndefined(value.content)) {
      var infowindow = new google.maps.InfoWindow({
        content: value.content
      });
      marker.addListener('click', function () {
        if (prevInfowindow) {
          prevInfowindow.close();
        }
        prevInfowindow = infowindow;
        infowindow.open(globals.location.currentMap, marker);
      });
    }
    globals.location.markers.push(marker);

    // Push the location
    // globals.location.locations.push(new google.maps.LatLng(value.lat, value.lng));
  });

  /**
   * Calculate markers Bounds & center map
   * Store as global var so we can retrigger saved markers on resize
   * @type {google.maps.LatLngBounds}
   */
  globals.location.bounds = new google.maps.LatLngBounds();
  for (var i = 0; i < globals.location.markers.length; i++) {
    globals.location.bounds.extend(globals.location.markers[i].getPosition());
  }
  globals.location.currentMap.fitBounds(globals.location.bounds);

  /** Set max zoom */
  _.delay(function (text) {
    if (globals.location.currentMap.getZoom() > 17) {
      globals.location.currentMap.setZoom(17);
    }
  }, 1000);

  /**
   * On resize, recalculate bounds & center map
   */
  $(window).on('resize', _.debounce(function () {
    globals.location.currentMap.fitBounds(globals.location.bounds);

    _.delay(function (text) {
      if (globals.location.currentMap.getZoom() > 17) {
        globals.location.currentMap.setZoom(17);
      }
    }, 1000);
  }, 150));

  if (globals.location.hasSearch) {
    globals.location.initAutocomplete();
  }

};

/**
 * Init function run by Google Maps V3 callback function
 */
globals.location.initAutocomplete = function () {
  // Create the search box and link it to the UI element.
  var input = document.getElementById('pac-input');
  var options = {
    componentRestrictions: {country: 'be'}
  };

  var searchBox = new google.maps.places.Autocomplete(input, options);
  var markers = [];
  // Listen for the event fired when the user selects a prediction and retrieve
  // more details for that place.
  searchBox.addListener('place_changed', function () {

    var place = searchBox.getPlace();

    if (place.length === 0) {
      return;
    }

    // Clear out the old markers.
    markers.forEach(function (marker) {
      marker.setMap(null);
    });
    markers = [];

    // For each place, get the icon, name and location.
    var bounds = new google.maps.LatLngBounds();

    // Create a marker for each place.
    markers.push(new google.maps.Marker({
      map: globals.location.currentMap, // icon: icon,
      title: place.name, animation: google.maps.Animation.DROP, position: place.geometry.location
    }));
    if (place.geometry.viewport) {
      // Only geocodes have viewport.
      bounds.union(place.geometry.viewport);
    } else {
      bounds.extend(place.geometry.location);
    }
    // });

    // Get closest location
    var closestLocation = [];
    _.each(globals.location.markers, function (value, key) {
      // calculate location distance
      var distance = google.maps.geometry.spherical.computeDistanceBetween(value.getPosition(), place.geometry.location);

      // First check so we can't compare yet
      if (key === 0) {
        closestLocation = [key, distance, value];
      }
      // Then check if new location is closer than previous
      else if (closestLocation[1] > distance) {
        closestLocation = [key, distance, value];
      }
    });

    // Create bound area between searched location & closest marker
    var loc = closestLocation[2].getPosition();
    bounds.extend(loc);
    globals.location.currentMap.fitBounds(bounds);
  });
};
