/**
 * Global events
 */
/*global globals: true*/
globals = {};
globals.events = {
    click: 'touchend' in document ? 'touchend' : 'click'
};

/**
 * Global helper, get url query strings as an object
 * @returns {}
 */
globals.getQueryString = function () {
    // This function is anonymous, is executed immediately and
    // the return value is assigned to QueryString!
    var queryString = {};
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        // If first entry with this name
        if (typeof queryString[pair[0]] === 'undefined') {
            queryString[pair[0]] = decodeURIComponent(pair[1]);
            // If second entry with this name
        } else if (typeof queryString[pair[0]] === 'string') {
            var arr = [queryString[pair[0]], decodeURIComponent(pair[1])];
            queryString[pair[0]] = arr;
            // If third or later entry with this name
        } else {
            queryString[pair[0]].push(decodeURIComponent(pair[1]));
        }
    }
    return queryString;
};

/**
 * Helper to remove a given string as query param in the url
 * @param url
 * @param parameter
 * @returns {*}
 */
globals.removeURLParameter = function (url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url = urlparts[0] + '?' + pars.join('&');
        return url;
    } else {
        return url;
    }
};

/**
 * Small helper to check if value is undefined
 * @param e
 * @returns {boolean}
 */
globals.isUndefined = function (e) {
    if (typeof e === 'undefined'){
        return true;
    }
};

