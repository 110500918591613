/**
 * Overlay behavior:
 *    - Add overlay and populate overlay with data
 *    - Remove overlay
 * @data: markup to populate overlay
 */
globals.responsiveTtables = {};

globals.responsiveTtables.init = function () {

    $('table').each(function () {
        $(this).wrap('<div class="table-responsive"></div>');
    });
};