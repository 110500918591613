/**
 * Generate mobileMenu
 * @type {}
 */
globals.mobileMenu = {};

/**
 * Init mobile menu
 */
globals.mobileMenu.init = function () {
    $('.js_mobile-menu-open').on(globals.events.click, function () {
        $('header.main div.menu').addClass('active');
        $('.js_mobile-menu-close').removeClass('hidden');
    });
    $('.js_mobile-menu-close').on(globals.events.click, function () {
        $('header.main div.menu').removeClass('active');
        $(this).addClass('hidden');
    });
};