//Placeholder behavior. Replace all form labels by placeholder tags.
//Only if Modernizr.placeholder
Drupal.behaviors.masonary = {
    attach: function (context) {
        var $this = Drupal.behaviors.masonary;
        //if ($('body').hasClass('.front')) {
        $this.setLayout();
        //}
    },
    setLayout: function () {
        //Launch vanilla JS
        if ($('.view-blog').length > 0) {
            imagesLoaded(document.querySelector('.view-blog'), function (instance) {
                var msnryHome = new Masonry('.view-blog .content-region ', {
                    itemSelector: '.list-child'
                });
            });
        }
        if ($('.view-taxonomy-term').length > 0) {
            imagesLoaded(document.querySelector('.view-taxonomy-term'), function (instance) {
                var msnryTaxonomy = new Masonry('.view-taxonomy-term .content-region ', {
                    itemSelector: '.list-child'
                });
            });
        }
    }
};
