/**
 * Generate location namespace
 * @type {}
 */
globals.formFeedback = {};

globals.formFeedback.resetForm = function (formID) {
    // Reset form values
    $(formID).find('form')[0].reset();

    // Reset
    $(formID + ' .error').each(function () {
        $(this).removeClass('error');
    });
    $(formID + ' .messages').each(function () {
        $(this).remove();
    });
};

globals.formFeedback.openOverlay = function (formID) {
    $(formID).find('.form-success-message').removeClass('seems-hidden');

    globals.formFeedback.closeOverlay();
};

globals.formFeedback.closeOverlay = function () {
    $('.js_form-message-close-overlay').on(globals.events.click, function () {
        $(this).closest('.form-success-message').addClass('seems-hidden');
    });
};
