//Placeholder behavior. Replace all form labels by placeholder tags.
//Only if Modernizr.placeholder
Drupal.behaviors.search = {
  attach: function(context) {
    var $this = Drupal.behaviors.search;
    $this.setSearch();
      $('.js_toggle-search').on('click',function(){
         $('.search-container').toggleClass('hidden');
          // $('#views-exposed-form-search-page .views-widget input').focus();
      });
  },
  setSearch: function() {
  }
};

