/**
 * Fallback for static pages to fake Drupal.behaviors scope
 */

/*global Drupal:true */
if (typeof Drupal === 'undefined') {
    Drupal = {};
    Drupal.t = function (i) {
        return i;
    };
    Drupal.behaviors = {};
    Drupal.behaviors.fakeNamespace = true;
}
