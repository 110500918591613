//Set share space
globals.collapsable = {};
globals.collapsable.init = function () {
    $('.js_collapsable').each(function () {
        var trigger = $(this).find('.js_collapsable-trigger'),
            _this = $(this),
            target = $(this).find('.js_collapsable-target');

        trigger.on(globals.events.click, function () {
            _this.toggleClass('shown');
        });
    });
    $('.js_fga-collapsable').each(function () {
        var trigger = $(this).find('.js_fga-collapsable-trigger'), _this = $(this), target = $(this).find('.js_fga-collapsable-target');

        trigger.on(globals.events.click, function (event) {
            var _this = $(this);
            if (event.target.tagName !== 'A') {
                // check if parent is not A
                if (event.target.parentNode.tagName !== 'A') {
                    trigger.toggleClass('active');
                } else {
                    window.location.href = _this.find('a').attr('href');
                    event.preventDefault();

                }
            } else {
                window.location.href = _this.find('a').attr('href');
                event.preventDefault();
            }
        });
    });
};

