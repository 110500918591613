/**
 * init all drupal behaviors attach
 */
document.addEventListener('DOMContentLoaded', function (event) {
    if (Drupal.behaviors.fakeNamespace) {
        for (var key in Drupal.behaviors) {
            if (typeof Drupal.behaviors[key] === 'object') {
                Drupal.behaviors[key].attach();
            }
        }
    }

    /**
     * Init all globals init functions on DOM ready
     */
    for (var k in globals) {
        if (typeof globals[k] === 'object') {
            if (typeof globals[k].init === 'function') {
                globals[k].init();
            }
        }
    }
});
