/**
 * Overlay functions
 */
Drupal.behaviors.smShare = {
    attach: function (context) {
        $('.js_smShare-open-overlay').on(globals.events.click, function () {
            globals.overlay.open('<p class="title">' + Drupal.t('Share this article on') + '</p><div class="sm-icons"><span class="globals.on-facebook js_smShare-tigger-fb">Facebook</span><span class="globals.on-twitter js_smShare-tigger-tw">Twitter</span></div>', 'sm');
            globals.smShare.bind();
        });

    }
};

//Set share space
globals.smShare = {};
globals.smShare.bind = function () {
    //Remote trigger facebook share
    $('.js_smShare-tigger-fb').on(globals.events.click, function () {
        var button = $('.at-svc-facebook');
        button[0].click();
        globals.overlay.destroy();
    });
    //Remote trigger twitter share
    $('.js_smShare-tigger-tw').on(globals.events.click, function () {
        var button = $('.at-svc-twitter');
        button[0].click();
        globals.overlay.destroy();
    });
};
