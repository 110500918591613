/**
 * Cookie notification bar
 */
Drupal.behaviors.cookienotification = {
    attach: function (context) {
        Drupal.behaviors.cookienotification.checkCookie();
    },
    checkCookie: function () {
        // If cookie isn't set, show notification
        if (!Cookies.get('cookies_accepted')) {
            //show notification
            $('.cookie-notice').removeClass('hidden');
            //bind button
            Drupal.behaviors.cookienotification.bindAccept();
        }
    },
    bindAccept: function () {
        //Bind click event
        $('.js_cookie-accept').on(globals.events.click, function () {
            //Set cookie
            Drupal.behaviors.cookienotification.setCookie();

            //Hide notification

            $('.cookie-notice').slideUp();
        });
    },
    setCookie: function () {
        //set cookie
        Cookies.set('cookies_accepted', true, {expires: 365});
    }
};

