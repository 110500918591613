Drupal.behaviors.scroll = {
  attach: function(context) {
    $('.js_scroll').on('click',function (e) {
      e.preventDefault();

      var target = this.hash,
          $target = $(target),
          offset = 40;

      $('html, body').stop().animate({
        'scrollTop': $target.offset().top - offset
      }, 900, 'swing', function () {
        //window.location.hash = target;
      });
    });
  }
};