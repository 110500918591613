/**
 * Generate job namespace
 * @type {}
 */
globals.job = {};

globals.job.init = function () {
  $('.js_job-filter-source').on(globals.events.click, 'input', function () {
    globals.job.resetFilter();
    globals.job.filterResults();
  });
  $('.js_job-filter-source select').on('change', function () {
    globals.job.resetFilter();
    globals.job.filterResults();
  });
};

globals.job.filterResults = function () {
  var boxes = $('.js_job-filter-source input[type="checkbox"]');

  // Loop through all checkboxes
  // Check for checkes boxes
  if (boxes.length !== $('.js_job-filter-source input[type="checkbox"]:not(:checked)').length) {
    // Hide all jobs who don't match the selected checkboxes
    $('.js_job-filter-source input[type="checkbox"]:not(:checked)').each(function () {
      $('.js_job-filter-target li[data-filter="' + $(this).val() + '"] ').each(function () {
        $(this).addClass('seems-hidden');
      });
    });
  }

  // Now check if we have ZERO results
  if($('.js_job-filter-target > li.seems-hidden').length === boxes.length){
    globals.job.showEmpty();
  }

  globals.job.typeFilter();
};

globals.job.showEmpty = function () {
    $('.no-filter-available').removeClass('hidden');
};
globals.job.typeFilter = function () {
  var boxes = $('.js_job-filter-source input[type="checkbox"]');
  // Apply type filter
  var type = $('.select-filter select').find(':selected').val();

  // Check if we have a value from the filter, assume the user selected "ALL"
  if (type.length > 0) {
    if ($('.vacancies-teasers li[data-type="' + type + '"]').length > 0) {
      $('.js_job-filter-target li:not(.seems-hidden):not([data-type="' + type + '"])').addClass('seems-hidden');
    } else if ($('.vacancies-teasers li[data-type="' + type + '"]').length === 0) {
      $('.js_job-filter-target li:not(.seems-hidden):not([data-type="' + type + '"])').addClass('seems-hidden');
      $('.no-filter-available').removeClass('hidden');
    }
  }

  // Now check if we have ZERO results
  if($('.js_job-filter-target > li.seems-hidden').length === boxes.length){
    globals.job.showEmpty();
  }
};

globals.job.resetFilter = function () {
  $('.js_job-filter-target li.seems-hidden').removeClass('seems-hidden');
  $('.js_job-filter-target > div').removeClass('hidden');
  $('.no-filter-available').addClass('hidden');
};
